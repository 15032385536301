.services-banner {
    &__video {
        width: 100%;
        height: auto;
    }
}

.services-banner__video {
    width: 100%;
    height: auto;
}


/**
* Template Name: Append
* Template URL: https://bootstrapmade.com/append-bootstrap-website-template/
* Updated: May 18 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --default-font: "Poppins", sans-serif;
    --heading-font: "Montserrat", sans-serif;
    --nav-font: "Poppins", sans-serif;
}

/* Global Colors */
:root {
    /* Background Color - This color is applied to the background of the entire website as well as individual sections. */
    --background-color: #ffffff;

    /* Default Color - This is the default color used for the majority of the text content. */
    --default-color: #212529;

    /* Heading Color - This color is used for titles, headings and secondary elements. */
    --heading-color: #32353a;

    /* Accent Color - This is the main accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out. */
    --accent-color: #e84545;

    /* Contrast Color - This is a color used for text when the background color is one of the heading, accent, or default colors. Its purpose is to ensure proper contrast and readability when placed over these more dominant colors. */
    --contrast-color: #ffffff;
}

/* Nav Menu Colors */
:root {
    /* Nav Color - This is the default color of the main navmenu links. */
    --nav-color: #3a3939;

    /* Nav Hover Color - This color is applied to main navmenu links when they are hovered over. */
    --nav-hover-color: #e84545;

    /* Nav Dropdown Background Color - This color is used as the background for dropdown boxes that appear when hovering over primary navigation items. */
    --nav-dropdown-background-color: #ffffff;

    /* Nav Dropdown Color - This color is used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-color: #3a3939;

    /* Nav Dropdown Hover Color - Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
    --nav-dropdown-hover-color: #e84545;
}

/* Smooth scroll */
:root {
    scroll-behavior: smooth;
}

/*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
}

a {
    color: var(--accent-color);
    text-decoration: none;
    transition: 0.3s;
}

a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
}

/*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
  
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.header {
    color: #ffffff;
    background-color: #686868;
    transition: all 0.5s;
    z-index: 997;
}

.header .col-lg-10 {
    background: #fff;
    padding: 0 95px;
    background-image: linear-gradient(to right, #ffffff, #dad7d7, #ffffff);
}

.header .logo {
    line-height: 1;
}

.header .logo img {
    max-height: 60px;
    margin-right: 8px;
    padding-left: 25px;
}

.top-foot h3 {
    font-size: 18px;
    padding-left: 14px;
    font-weight: 700;
    /* width: 60%; */
    color: #000;
}

.top-foot .col-md-4 {}

.top-foot p {
    padding-left: 15px;
}

.top-foot i {
    background: #212121;
    width: 45px;
    height: 45px;
    color: white;
    font-size: 19px;
    line-height: 0;
    padding: 14px;
    position: relative;
    /* left: 35px; */
}

.top-foot .info-item.aos-init.aos-animate {
    position: relative;
}

.top-foot.container {
    margin-left: 155px;
}


.top-foot {
    padding-left: 15px;
    padding-top: 75px;
    padding-bottom: 50px;
}

.info-item.aos-init.aos-animate {
    padding: 30px;
    background-image: linear-gradient(to right, #ffffff, #dad7d7, #ffffff);
}





.header .logo h1 {
    font-size: 24px;
    margin: 0;
    font-weight: 600;
    color: var(--heading-color);
}

.header .logo span {
    color: var(--accent-color);
    font-size: 24px;
    font-weight: 600;
    padding-left: 3px;
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
    color: var(--contrast-color);
    background: #0f0f0f;
    font-size: 14px;
    padding: 12px 53px;
    margin: 0;
    border-radius: 0;
    transition: 0.3s;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.navmenu {
    padding: 0;
    display: inline-block;
}

.header a.btn-getstarted.f-right {
    float: right;
    margin-top: 7px;
    margin-bottom: 7px;
}

.navbar-toggler {
    margin-top: 7px;
    margin-left: 7px;

}

.social-med {
    width: 15%;
}

.secont-no {
    /* width: 70%; */
}

.top-header .first-location {
    color: #dad6d6;
    /* width: 15%; */
    font-size: 12px;
}

.top-header ul.social-icon li {
    padding: 0px 5px;
}

.top-foot .info-item.aos-init.aos-animate {
    display: inline-flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: stretch !important;
    align-content: space-around !important;
    justify-content: center !important;
}



@media (max-width: 1200px) {
    .header .logo {
        order: 1;
    }

    .header .btn-getstarted {
        order: 2;
        margin: 0 15px 0 0;
        padding: 6px 20px;
    }

    .header .navmenu {
        order: 3;
    }
}

.scrolled .header {
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
}

/* Index Page Header
  ------------------------------*/
.index-page .header {
    --background-color: rgba(255, 255, 255, 0);
    --heading-color: #ffffff;
    --nav-color: rgba(255, 255, 255, 0.5);
    --nav-hover-color: #ffffff;
}

/* Index Page Header on Scroll
  ------------------------------*/
.index-page.scrolled .header {
    --background-color: #ffffff;
    --heading-color: #32353a;
    --nav-color: #3a3939;
    --nav-hover-color: #e84545;
}

/*------------------------top menu-------------------------*/
.top-header {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-evenly;
    background: #0f0f0f;
}

.top-header ul {
    margin: 0;
    padding: 12px 0px;
}

.top-header ul li {
    display: inline-block;
    padding: 0 20px;
}

.top-header ul li a {
    font-size: 12px;
    color: #dad6d6;
}

.top-header .first-location {
    color: #dad6d6;
    font-size: 12px;
    text-align: center;
}

.top-header .container {
    display: flex;
    align-items: center;
}

.navmenu ul a {
    text-transform: uppercase;
    font-weight: 600;
}

.container-fluid.position-relative.d-flex.align-items-center.justify-content-between {

    margin: 0;
    padding: 0;
}

.top-header .first-location i {
    padding: 0px 10px;
}

.top-header .secont-no i {
    padding: 0px 10px;
    border-left: 2px solid #d9d9d9;
}

.newletter-forms input[type="email"] {
    border-radius: 0;
    line-height: 2;
    background-color: #0f0f0f;
    border: 1px solid #4a4a4a;
    padding: 10px 20px;
    width: 100%;
}

.newletter-forms button.submit {
    color: #323030;
    background-image: linear-gradient(to right, #ffffff 0%, #ffffff 25%, #aeacac 50%, #ffffff 75%, #ffffff 100%) !important;
    font-size: 14px;
    padding: 12px 20px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 15px;
}

.newletter-forms button.submit a {
    color: #333;
}








/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Navmenu - Desktop */
@media (min-width: 992px) {
    .navmenu {
        padding: 0;
    }

    .navmenu ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    .navmenu li {
        position: relative;
    }

    .navmenu a,
    .navmenu a:focus {
        color: var(--nav-color);
        padding: 18px 15px;
        font-size: 16px;
        font-family: var(--nav-font);
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        transition: 0.3s;
    }

    .navmenu a i,
    .navmenu a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
        transition: 0.3s;
    }

    .navmenu li:last-child a {
        padding-right: 0;
    }

    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
        color: var(--nav-hover-color);
    }

    .navmenu .dropdown ul {
        margin: 0;
        padding: 10px 0;
        background: var(--nav-dropdown-background-color);
        display: block;
        position: absolute;
        visibility: hidden;
        left: 14px;
        top: 130%;
        opacity: 0;
        transition: 0.3s;
        border-radius: 4px;
        z-index: 99;
        box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 85%);
    }

    .navmenu .dropdown ul li {
        min-width: 200px;
    }

    .navmenu .dropdown ul a {
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        color: var(--nav-dropdown-color);
    }

    .navmenu .dropdown ul a i {
        font-size: 12px;
    }

    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
        color: var(--nav-dropdown-hover-color);
    }

    .navmenu .dropdown:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .navmenu .dropdown .dropdown ul {
        top: 0;
        left: -90%;
        visibility: hidden;
    }

    .navmenu .dropdown .dropdown:hover>ul {
        opacity: 1;
        top: 0;
        left: -100%;
        visibility: visible;
    }
}

/* Navmenu - Mobile */
/* @media (max-width: 1199px) {
    .mobile-nav-toggle {
        color: var(--nav-color);
        font-size: 28px;
        line-height: 0;
        margin-right: 10px;
        cursor: pointer;
        transition: color 0.3s;
    }

    .navmenu {
        padding: 0;
        z-index: 9997;
    }



    .navmenu a,
    .navmenu a:focus {
        color: var(--nav-dropdown-color);
        padding: 10px 20px;
        font-family: var(--nav-font);
        font-size: 17px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        transition: 0.3s;
    }

    .navmenu a i,
    .navmenu a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.3s;
        background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }

    .navmenu a i:hover,
    .navmenu a:focus i:hover {
        background-color: var(--accent-color);
        color: var(--contrast-color);
    }

    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
        color: var(--nav-dropdown-hover-color);
    }

    .navmenu .active i,
    .navmenu .active:focus i {
        background-color: var(--accent-color);
        color: var(--contrast-color);
        transform: rotate(180deg);
    }

    .navmenu .dropdown ul {

        z-index: 99;
        padding: 10px 0;
        margin: 10px 0px;
        background-color: var(--nav-dropdown-background-color);
        border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
        box-shadow: none;
        transition: all 0.5s ease-in-out;
    }

    .navmenu .dropdown ul ul {
        background-color: rgba(33, 37, 41, 0.1);
    }

    .navmenu .dropdown>.dropdown-active {
        display: block;
        background-color: rgba(33, 37, 41, 0.03);
    }

    .mobile-nav-active {
        overflow: hidden;
    }

    .mobile-nav-active .mobile-nav-toggle {
        color: #fff;
        position: absolute;
        font-size: 32px;
        top: 15px;
        right: 15px;
        margin-right: 0;
        z-index: 9999;
    }

    .mobile-nav-active .navmenu {
        position: fixed;
        overflow: hidden;
        inset: 0;
        background: rgba(33, 37, 41, 0.8);
        transition: 0.3s;
    }

    .mobile-nav-active .navmenu>ul {
        display: block;
    }
} */

/*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
.footer {
    --background-color: #333333;
    color: var(--default-color);
    background-color: #333333;
    font-size: 14px;
    padding-bottom: 25px;
    position: relative;
}

.footer .footer-top {
    padding-top: 50px;
}

.footer .footer-about .logo {
    margin-bottom: 25px;
}

.footer .footer-about .logo img {
    max-height: 80px;
    margin-right: 6px;
    width: 300px;
}

.footer .footer-about .logo span {
    color: var(--heading-color);
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: var(--heading-font);
}

.footer .footer-about p {
    font-size: 14px;
    font-family: var(--heading-font);
    color: #d5d4d4c2;
}

.footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin-right: 15px;
    transition: 0.3s;
}

.footer .social-links a:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
}

.footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    color: #f2f1f1;
    padding-bottom: 20px;
}

.footer .social-links a i {
    color: #f2f1f1;
}

.footer .footer-links {
    margin-bottom: 30px;
}

.footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .footer-links ul i {
    padding-right: 2px;
    font-size: 12px;
    line-height: 0;
}

.footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.footer .footer-links ul li:first-child {
    padding-top: 0;
}

.footer .footer-links ul a {
    color: #d5d4d4c2;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
}

.footer .footer-links ul a:hover {
    color: var(--accent-color);
}

.footer .footer-contact p {
    margin-bottom: 5px;
}

.footer .copyright {
    padding-top: 25px;
    border-top: 1px solid #a8a8ae78;
    padding-bottom: 0;
}

.footer .copyright p {
    margin-bottom: 0;
    text-align: right;
    color: #d5d4d4c2;
    font-size: 12px;
}

.footer .credits {
    margin-top: 6px;
    font-size: 13px;
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {
    position: fixed;
    inset: 0;
    z-index: 999999;
    overflow: hidden;
    background: var(--background-color);
    transition: all 0.6s ease-out;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #ffffff;
    border-color: var(--accent-color) transparent var(--accent-color) transparent;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1.5s linear infinite;
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.scroll-top i {
    font-size: 24px;
    color: var(--contrast-color);
    line-height: 0;
}

.scroll-top:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: var(--contrast-color);
}

.scroll-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }

    .home-content {
        padding-bottom: 60px !important;
    }

    .container.sec-servic-three {
        padding-top: 60px !important;

    }

    .service-item {
        margin-bottom: 10px;
    }

    .services-provider .button-home {
        margin-top: 0px !important;
    }

    .button-sect {
        justify-content: center;
    }

    .service_slider .member {
        margin-left: 0px !important;
    }

    .top-foot {
        padding-left: 0px !important;
    }

    .social-links {
        margin-bottom: 20px;
    }

    .footer h4 {
        margin-bottom: 0px;
    }

    .ourvalues .icon-box {
        margin-bottom: 10px !important;
        height: auto !important;
    }

    .teamsec img {
        width: 40% !important;

    }

    .left-div p br {
        display: none;
    }

    .left-div p {
        margin-bottom: 30px;
        line-height: 30px !important;

    }

    .Right-div {
        margin-bottom: 26px;

    }

    .section1 {
        margin-top: 0px !important;
    }

    .contact .php-email-form,
    .contact-contact {
        margin: 0px !important;

    }

    .contact .php-email-form {
        margin-bottom: 30px !important;
    }

    .foo-contact {
        flex-wrap: wrap !important;
    }

    .followus h3 {
        line-height: 22px !important;
        margin-top: 30px;
    }

    .followus a i {
        font-size: 22px !important;
    }

    .contact-contact .social-links {
        margin-bottom: 0px;
    }

    .map {
        margin-top: 30px;
        padding: 0px !important;
    }

    .whychoose {
        padding-bottom: 0px !important;
    }

    .appoint-form {
        padding: 0px !important;
    }
    .appoint-form .form-control {
        background-color: #0f0f0f !important;
        border: none !important;
        border-radius: 0 !important;
        color: #fff !important;
        line-height: 2 !important;
    }
}

/*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
.page-title {
    color: var(--default-color);
    background-color: var(--background-color);
    position: relative;
}

.page-title .heading {
    padding: 80px 0;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.page-title .heading h1 {
    font-size: 50px;
    font-weight: 800;
}

.services-banner p {
    line-height: 45px;
}

.page-title nav {
    background-color: color-mix(in srgb, var(--default-color), transparent 95%);
    padding: 20px 0;
}

.page-title nav ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.page-title nav ol li+li {
    padding-left: 10px;
}

.page-title nav ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.Right-div {
    width: 50%;
    float: right;
    position: relative;
    text-align: right;
    margin-top: -45px;
}

.left-div p {
    line-height: 22px;
    font-weight: 600;
    color: #d6d6d6;
}

.left-div h3 {
    font-weight: 700;
    color: #d6d6d6;
}

nav.secound-banner-sec {
    background: #333333;
    height: 95px;
    padding: 26px;
}

.Right-div a.btn-getstarted.f-right {
    color: #323030;
    background-image: linear-gradient(to right, #ffffff, #dad7d7, #ffffff);
    font-size: 14px;
    padding: 12px 25px;
}

.main-banner h1,
.main-banner p {
    color: #ffffffd6;
}

.services-banner {
    /* background: url(../images/service-banner.png),; */
    background:
        linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(../images/service-banner.png) no-repeat bottom;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}

.services-banner {
    margin-top: -3px;
}

.services-list i.bi.bi-arrow-right-circle {
    display: none;
}

.service-box.contact-form p {
    text-align: center;
    color: #f2eeee;
    line-height: 1;
    padding: 0;
}

.service-box.contact-form h4 {
    text-align: center;
    color: #f2eeee;
    margin-bottom: 0;
}

.service-box.contact-form h4 {
    margin-bottom: 0;
}

.appoint-form {
    padding: 12px 0px;
}

.appoint-form .form-control {
    border-radius: 0!important;
    line-height: 2!important;
    background-color: #0f0f0f!important;
    border: none!important;
    color: #fff!important;
}

.appoint-form  .form-select{
height: 44px;
}
.appoint-form .form-control::placeholder {
    color: #929292;
    font-size: 14px;
}

.appoint-form .btn-appoitment {
    color: #323030;
    background: #d6d6d6;
    background-image: linear-gradient(to right, #ffffff, #dad7d7, #ffffff);
    font-size: 14px;
    padding: 12px 25px;
    text-transform: uppercase;
    width: 100%;
}

.service-details .service-box+.service-box {
    margin-top: 30px;
    padding: 100px 20px;
}

.comman-issue p {
    font-size: 14px;
}

.services-des h3 {
    line-height: 62px;
}

@media (min-width: 992px) {
    /* .services-des {
        padding-left: 80px !important;
        padding-right: 68px !important;
        margin-top: 80px;
    } */
}

.Benefits {
    border-top: 1px solid #727272;
    border-bottom: 1px solid #727272;
    width: 1140px;
    margin: auto;
}

.Benefits .description {
    margin-bottom: 0px;
}

.BenefitsSection {
    width: 100% !important;
}

.Benefits p {
    text-align: left;
    font-size: 14px;
}

.Benefits h2 {
    text-align: left;
}

.Benefits h4.title a {
    font-size: 22px;
}

.Benefits {
    padding: 50px 0px;
}

.Right-div img {
    width: 300px;
    position: absolute;
    right: 36%;
    top: -90px;
}

/*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
section {
    color: var(--default-color);
    /* background-image: linear-gradient(to right, rgb(147 143 143), rgb(255 255 255)); */
    background-image: linear-gradient(to right, white, #bab8b8, white);
    scroll-margin-top: 98px;
    overflow: clip;
    padding: 60px 0px;
}

.section1 {
    color: var(--default-color);
    background-image: none !important;
    padding: 60px 0;
    /* scroll-margin-top: 98px; */
    overflow: clip;
}


@media (max-width: 1199px) {

    section,
    .section {
        scroll-margin-top: 64px;
    }
}

/*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
.section-title {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
}

.section-title h2 {
    font-size: 32px;
    font-weight: 700;
    position: relative;
}

/*
  .section-title h2:before,
  .section-title h2:after {
    content: "";
    width: 50px;
    height: 2px;
    background: var(--accent-color);
    display: inline-block;
  }
    */
.section-title h2:before {
    margin: 0 15px 10px 0;
}


.section-title h2:after {
    margin: 0 0 10px 15px;
}

.section-title p {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
.hero {
    --default-color: #ffffff;
    --background-color: #000000;
    --heading-color: #ffffff;
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 160px 0 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.hero:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 50%);
    position: absolute;
    inset: 0;
    z-index: 2;
}

.hero .container {
    position: relative;
    z-index: 3;
}

.hero h2 {
    color: var(--contrast-color);
    margin: 0;
    font-size: 44px;
    font-weight: 700;
}

.hero p {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 5px 0 0 0;
    font-size: 20px;
}

.hero .sign-up-form {
    margin-top: 20px;
    padding: 10px;
    border-radius: 7px;
    background: #fff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
}

.hero .sign-up-form .form-control {
    border: none;
}

.hero .sign-up-form .form-control:active,
.hero .sign-up-form .form-control:focus {
    outline: none;
    box-shadow: none;
}

.hero .sign-up-form input[type=submit] {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--accent-color);
    border-color: var(--accent-color);
    padding: 8px 20px 10px 20px;
    border-radius: 7px;
    color: var(--contrast-color);
}

.hero .sign-up-form input[type=submit]:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 10%);
}

@media (max-width: 768px) {
    .hero h2 {
        font-size: 32px;
    }

    .hero p {
        font-size: 18px;
    }

    .four-secton-box {
        width: 100% !important;
    }

    .four-secton-box .info-item.aos-init.aos-animate {
        margin-bottom: 20px;
    }
}

/*--------------------------------------------------------------
  # Clients Section
  --------------------------------------------------------------*/
.clients {
    padding: 20px 0;
}

.clients .client-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.clients .client-logo img {
    padding: 20px 40px;
    max-width: 90%;
    transition: 0.3s;
    opacity: 0.5;
    filter: grayscale(100);
}

.clients .client-logo img:hover {
    filter: none;
    opacity: 1;
}

@media (max-width: 640px) {
    .clients .client-logo img {
        padding: 20px;
    }

    .page-title .heading h1 {
        font-size: 30px;
    }

    .pricing .col-lg-4,
    .pricing .col-lg-3 {
        margin-bottom: 50px;
        margin-top: 0px;
    }
}

/*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
.about {
    --background-color: #f4f4f4;
}

.about .content h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    padding: 10px 20px;
    background: color-mix(in srgb, var(--accent-color), transparent 95%);
    color: var(--accent-color);
    border-radius: 7px;
    display: inline-block;
}

.about .content h2 {
    font-weight: 700;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .read-more {
    background: var(--accent-color);
    color: var(--contrast-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 12px 24px;
    border-radius: 5px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.about .content .read-more i {
    font-size: 18px;
    margin-left: 5px;
    line-height: 0;
    transition: 0.3s;
}

.about .content .read-more:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
    padding-right: 19px;
}

.about .content .read-more:hover i {
    margin-left: 10px;
}

.about .icon-box {
    background-color: #333333;
    text-align: center;
    padding: 0 40px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    transition: all 0.3s ease-out 0s;
    height: 200px;
    margin-bottom: 50px;
}

.about .icon-box img {
    width: 80px;
    height: 80px;
    padding: 20px;
    margin: auto;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;
    margin-top: -30px;
    font-size: 32px;
    line-height: 0;
    transition: all 0.4s ease-out 0s;
    background-color: #000;
    color: #f0f0f0;
    justify-content: space-around;
}

.icon-boxes .how_it_works_heading {
    font-size: 15px;
    text-transform: uppercase;
}

.about .icon-box h4 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #ccc7c7;
}

.icon-boxes h3 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
}

.about .icon-box p {
    margin-bottom: 0;
    line-height: 20px;
    font-size: 14px;
    color: #d8d6d6;
}

.icon-box .text-center {
    margin-top: 35px;
}

.appointment p {
    line-height: 26px;
    text-align: center;
    margin-bottom: 40px;
}

.about .icon-box div.buy-btn {
    background: #000;
    color: #d7d3d3f5;
    font-size: 14px;
    padding: 3px 19px;
    width: fit-content;
    margin: auto;
}

.icon-boxes {
    text-align: center;
}

.about .icon-box:hover i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
}

.about .icon-boxes .col-md-6:nth-child(2) .icon-box,
.about .icon-boxes .col-md-6:nth-child(4) .icon-box {
    margin-top: 0;
}

@media (max-width: 768px) {

    .about .icon-boxes .col-md-6:nth-child(2) .icon-box,
    .about .icon-boxes .col-md-6:nth-child(4) .icon-box {
        margin-top: 0;
    }
}

/*--------------------------------------------------------------
  # Stats Section
  --------------------------------------------------------------*/
.stats {
    --default-color: #ffffff;
    --background-color: #000000;
    position: relative;
    padding: 0px 0;
}

.stats img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.stats:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 40%);
    position: absolute;
    inset: 0;
    z-index: 2;
}

.stats .container {
    position: relative;
    z-index: 3;
}

.stats .stats-item {}

.stats .stats-item span {
    font-size: 24px;
    display: block;
    color: var(--default-color);
    font-weight: 700;
}

.stats .stats-item p {
    padding: 0;
    line-height: 29px;
    padding-left: 15px;
    margin: 0;
    font-family: var(--heading-font);
    font-size: 16px;
    font-weight: 600;
    color: #f5f2f2;
}

.stats h4 {
    font-weight: 700;
    color: #FFF;
    font-size: 29px;
    padding-top: 25px;
}

.stats .stats-item .call-us {
    padding-left: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
}

/*--------------------------------------------------------------
  # Services Section
  --------------------------------------------------------------*/
.services .service-item {
    position: relative;
    padding-top: 10px;
}

.pt-0 {
    padding-top: 0px !important;
}

.services .service-item .icon {
    width: 70px;
    height: 70px;
    background: #333;
    position: relative;
    margin-right: 20px;
    line-height: 0;
}


.services .service-item .title {
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 16px;
}

.services .service-item .title a {
    color: var(--heading-color);
}

.services .service-item .title a:hover {
    color: var(--accent-color);
}

.services .service-item .description {
    line-height: 24px;
    font-size: 14px;
}

/*--------------------------------------------------------------
  # Features Section
  --------------------------------------------------------------*/
.features .features-item {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.features .features-item+.features-item {
    margin-top: 100px;
}

@media (max-width: 768px) {
    .features .features-item+.features-item {
        margin-top: 40px;
    }
}

.features .features-item h3 {
    font-weight: 700;
    font-size: 26px;
}

.features .features-item .btn-get-started {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
}

.features .features-item .btn-get-started:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 10%);
}

.features .features-item ul {
    list-style: none;
    padding: 0;
}

.features .features-item ul li {
    padding-bottom: 10px;
    display: flex;
    align-items: flex-start;
}

.features .features-item ul li:last-child {
    padding-bottom: 0;
}

.features .features-item ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--accent-color);
}

.features .features-item img {
    border: 6px solid var(--contrast-color);
    box-shadow: 0 15px 30px -10px color-mix(in srgb, var(--default-color), transparent 75%);
}

.features .features-item .features-img-bg {
    position: relative;
    min-height: 500px;
}

@media (max-width: 640px) {
    .features .features-item .features-img-bg {
        min-height: 300px;
    }
}

.features .features-item .features-img-bg img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.features .features-item .image-stack {
    display: grid;
    position: relative;
    grid-template-columns: repeat(12, 1fr);
}

.features .features-item .image-stack .stack-back {
    grid-column: 4/-1;
    grid-row: 1;
    width: 100%;
    z-index: 1;
}

.features .features-item .image-stack .stack-front {
    grid-row: 1;
    grid-column: 1/span 8;
    margin-top: 20%;
    width: 100%;
    z-index: 2;
}

a.btn-getstarted.f-right {
    color: #323030;
    background-image: linear-gradient(to right, #ffffff, #dad7d7, #ffffff);
    font-size: 14px;
    padding: 12px 25px;
}

page-title .heading h1 {
    font-size: 50px;
    font-weight: 700;
}

.button-home {
    margin: 50px 0;
}

.header a.btn-getstarted.f-right {
    background: #323030;
    color: #fff;
}




/*--------------------------------------------------------------
  # Portfolio Section
  --------------------------------------------------------------*/
.portfolio .portfolio-filters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
}

.portfolio .portfolio-filters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 10px 20px;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    font-family: var(--heading-font);
}

.portfolio .portfolio-filters li:hover,
.portfolio .portfolio-filters li.filter-active {
    color: var(--contrast-color);
    background-color: var(--accent-color);
}

.portfolio .portfolio-filters li:first-child {
    margin-left: 0;
}

.portfolio .portfolio-filters li:last-child {
    margin-right: 0;
}

@media (max-width: 575px) {
    .portfolio .portfolio-filters li {
        font-size: 14px;
        margin: 0 0 10px 0;
    }
}

.portfolio .portfolio-item {
    position: relative;
    overflow: hidden;
}

.portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: -100%;
    z-index: 3;
    transition: all ease-in-out 0.5s;
    background: color-mix(in srgb, var(--background-color), transparent 10%);
    padding: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    font-weight: 600;
    padding-right: 50px;
}

.portfolio .portfolio-item .portfolio-info p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 50px;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 50px;
    font-size: 24px;
    top: calc(50% - 14px);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    transition: 0.3s;
    line-height: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: var(--accent-color);
}

.portfolio .portfolio-item .portfolio-info .details-link {
    right: 14px;
    font-size: 28px;
}

.portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
}

/*--------------------------------------------------------------
  # Pricing Section
  --------------------------------------------------------------*/
.pricing {}

.pricing .section-title {
    margin-bottom: 0px;
}

.price-amount {
    text-align: center;
}

.pricing .pricing-item {
    padding: 15px 15px;
    color: #fff;
    background: #000;
    height: 100%;
    position: relative;
    border-radius: 0;
}

.price-head {
    background-image: linear-gradient(to right, #ffffff, #dad7d7, #ffffff);
    padding: 15px 0px;
}

.pricing h3 {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 25px;
    color: #2d2a2a;
    text-align: center;
}

.price-amount h4 {
    padding-top: 51px;
    text-align: center;
    display: inline-grid;
}

.pricing.section p {
    color: #fff;
}

.pricing .pricing-item p {
    text-align: center;
    line-height: 26px;
    padding-bottom: 15px;
}

.pricing .icon {
    margin: 30px auto 20px auto;
    width: 70px;
    height: 70px;
    background: var(--accent-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    transform-style: preserve-3d;
}

.pricing .icon i {
    color: var(--background-color);
    font-size: 28px;
    transition: ease-in-out 0.3s;
    line-height: 0;
}

.pricing .icon::before {
    position: absolute;
    content: "";
    height: 86px;
    width: 86px;
    border-radius: 50%;
    background: color-mix(in srgb, var(--accent-color), transparent 80%);
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
}

.pricing .icon::after {
    position: absolute;
    content: "";
    height: 102px;
    width: 102px;
    border-radius: 50%;
    background: color-mix(in srgb, var(--accent-color), transparent 90%);
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-2px);
}

.pricing h4 {
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-family: var(--heading-font);
    margin-bottom: 5px;
    text-align: center;
}

.pricing h4 sup {
    font-size: 28px;
}

video#myVideo {
    position: relative;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

/* .services-banner .container-fluid {
    padding-left: 0;
  } */

.pricing h4 span {
    color: #fefefe;
    font-size: 11px;
    text-transform: capitalize;
    font-weight: 400;
}

.pricing ul {
    padding: 20px 0 45px 0;
    background: #fff;
    list-style: none;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    text-align: left;
    line-height: 20px;
    margin: 0;
}

.pricing ul li {
    padding: 10px 0;
    margin: auto 20px;
    font-size: 14px;

    color: #2d2b2b;
    border-bottom: 2px solid #b1b1b1;

}

.pricing ul li span {
    width: 100%;
}

.appointment .service-box.contact-form {
    background: black;
    padding: 108px 20px;
    margin-top: -100px;
    position: absolute;
    width: 30%;
}

.pricing ul .na span {
    text-decoration: line-through;
    color: #2d2b2b;
}

.pricing ul i {
    color: #059652;
    font-size: 24px;
    padding-right: 3px;
}

.appointment .service-box.contact-form .appoint-form .form-control {
    border-radius: 0;
    line-height: 2;
    border: none;
    color: #bdbdbd;
}

input::-webkit-date-and-time-value {
    text-align: left;
}
.form-select{
    border: none;
    border-radius: 0;
    height: 36px;
}
.pricing_pickup .form-select{
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    height: auto;
}
.form-control {
    text-align: left !important;
    color: #fff;
    -webkit-appearance: none !important;

}
.date_time{
    width: 100%;
    border: none!important;
}
.Date-text .MuiOutlinedInput-root{
background-color: #0f0f0f!important;
height: 44px;
}

.pricingDate .MuiOutlinedInput-root{
    background-color: #fff!important;
    height: 37.6px;
    margin: 0px 15px;
    border: var(--bs-border-width) solid var(--bs-border-color)!important;
    border-radius: var(--bs-border-radius)!important;
    }
   .pricingDate .MuiInputBase-root  input{
        height: auto!important;
        color: #2d2a2a!important;
        font-family: var(--heading-font)!important;
        font-size: 1rem!important;
    
    
    }
   

.MuiOutlinedInput-root{
    color: #fff!important;
    font-family: var(--heading-font)!important;
    font-size: 1rem!important;
    background-color: #212529;
    border-radius: 0px!important;
    border: none!important;
}
.MuiInputBase-root input{
    height: auto!important;
    color: #fff!important;
    font-family: var(--heading-font)!important;
    font-size: 1rem!important;


}
.MuiOutlinedInput-notchedOutline{
    border-style: none!important;
}
.MuiFormControl-root button{
    color: #b3acac!important;

}
.MuiFormControl-root{
    width: 100%;


}
  
.form-control
{
    box-shadow: none!important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root input::placeholder{
    color: #b3acac!important;
    opacity: 1; /* Firefox */

}
::placeholder {
    color: #b3acac!important;
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: red;
  }


.pricing ul .na {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.pricing ul .na i {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.pricing ul .na span {
    text-decoration: line-through;
}

.pricing .buy-btn {
    color:#000;
    background-image: linear-gradient(to right, #ffffff, #dad7d7, #ffffff);
    display: inline-block;
    padding: 14px 25px 14px 25px;
    border-radius: 0;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 80%);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: var(--heading-font);
    transition: 0.3s;
    position: absolute;
    bottom: -40px;

    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
}

.pricing .buy-btn:hover {
    background: var(--accent-color) !important;
    color: var(--contrast-color)!important;
}

.logo-slider img {
    width: 200px;
    /* Adjust based on your logo sizes */

}

.logo-slider .card {
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service_slider .member {
    margin-left: 20px;
}

@media (min-width: 992px) {
    .pricing .featured {
        transform: scale(1.15);
    }
}

/*--------------------------------------------------------------
  # Faq Section
  --------------------------------------------------------------*/
.faq .content h3 {
    font-weight: 400;
    font-size: 34px;
}

.faq .content p {
    font-size: 15px;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.faq .faq-container .faq-item {
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 5px 25px 0px color-mix(in srgb, var(--default-color), transparent 90%);
    overflow: hidden;
}

.faq .faq-container .faq-item:last-child {
    margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0 30px 0 0;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
}

.faq .faq-container .faq-item h3 .num {
    color: var(--accent-color);
    padding-right: 5px;
}

.faq .faq-container .faq-item h3:hover {
    color: var(--accent-color);
}

.faq .faq-container .faq-item .faq-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
}

.faq .faq-container .faq-item .faq-content p {
    margin-bottom: 0;
    overflow: hidden;
}

.faq .faq-container .faq-item .faq-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    line-height: 0;
    transition: 0.3s;
    cursor: pointer;
}

.faq .faq-container .faq-item .faq-toggle:hover {
    color: var(--accent-color);
}

.faq .faq-container .faq-active h3 {
    color: var(--accent-color);
}

.faq .faq-container .faq-active .faq-content {
    grid-template-rows: 1fr;
    visibility: visible;
    opacity: 1;
    padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
    transform: rotate(90deg);
    color: var(--accent-color);
}

/*--------------------------------------------------------------
  # Team Section
  --------------------------------------------------------------*/
.team {
    --background-color: #f4f4f4;
}

.team .member {
    position: relative;
}

.team .member .member-img {
    margin: 0 80px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 4px solid var(--background-color);
    box-shadow: 0 15px 35px -10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
    .team .member .member-img {
        margin: 0 60px;
    }
}

.team .member .member-img img {
    position: relative;
    z-index: 1;
}

.team .member .member-img .social {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding-bottom: 20px;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
}

.team .member .member-img .social a {
    transition: 0.3s;
    color: var(--contrast-color);
    font-size: 20px;
    margin: 0 8px;
}

.team .member .member-img .social a:hover {
    color: var(--accent-color);
}

.team .member .member-info {
    margin-top: 30px;
}

.team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 6px;
    font-size: 18px;
}

.team .member .member-info span {
    font-style: italic;
    display: block;
    font-size: 15px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin-bottom: 10px;
}

.team .member .member-info p {
    margin-bottom: 0;
    font-size: 14px;
}

.team .member:hover .member-img .social {
    padding-bottom: 0;
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
.call-to-action {
    --background-color: #000000;
    --default-color: #ffffff;
    --contrast-color: #ffffff;
    padding: 80px 0;
    position: relative;
    clip-path: inset(0);
}

.call-to-action img {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.call-to-action:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 50%);
    position: absolute;
    inset: 0;
    z-index: 2;
}

.call-to-action .container {
    position: relative;
    z-index: 3;
}

.call-to-action h3 {
    font-size: 28px;
    font-weight: 700;
    color: var(--default-color);
}

.call-to-action p {
    color: var(--default-color);
}

.call-to-action .cta-btn {
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 5px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid var(--contrast-color);
    color: var(--contrast-color);
}

.call-to-action .cta-btn:hover {
    background: var(--accent-color);
    border: 2px solid var(--accent-color);
}

/*--------------------------------------------------------------
  # Testimonials Section
  --------------------------------------------------------------*/
.testimonials {
    --background-color: #f4f4f4 !important;
}
.testimonials {
    padding-top: 160px;
}
.testimonials .info h3 {
    font-weight: 700;
    font-size: 32px;
}

.testimonials .swiper {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    box-sizing: content-box;
    min-height: 200px;
    position: relative;
    margin: 30px;
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    height: 90px;
    border-radius: 50px;
    border: 6px solid var(--background-color);
    margin-right: 10px;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 14px;
    margin: 0;
}

.testimonials .testimonial-item .stars {
    margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--accent-color), transparent 60%);
    font-size: 26px;
    line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
}

.testimonials .swiper-wrapper {
    height: auto;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
    border: none;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
}

@media (max-width: 767px) {

    .testimonials .testimonials-carousel,
    .testimonials .testimonials-slider {
        overflow: hidden;
    }

    .testimonials .testimonial-item {
        margin: 15px;
    }
.testimonials{
    padding-top: 1px;
}
    .header .col-lg-10 {
        background: #fff;
        padding: 0;
    }
}

/*--------------------------------------------------------------
  # Recent Posts Section
  --------------------------------------------------------------*/
.recent-posts article {
    box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
    padding: 30px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.recent-posts .post-img {
    max-height: 240px;
    margin: -30px -30px 15px -30px;
    overflow: hidden;
}

.recent-posts .post-category {
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin-bottom: 10px;
}

.recent-posts .title {
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 20px 0;
}

.recent-posts .title a {
    color: var(--heading-color);
    transition: 0.3s;
}

.recent-posts .title a:hover {
    color: var(--accent-color);
}

.recent-posts .post-author-img {
    width: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.recent-posts .post-author {
    font-weight: 600;
    margin-bottom: 5px;
}

.recent-posts .post-date {
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
.contact .info-item {
    background: color-mix(in srgb, var(--default-color), transparent 96%);
    padding: 30px;
}

.contact .info-item i {
    font-size: 38px;
    line-height: 0;
    color: var(--accent-color);
}

.contact .info-item h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 10px 0;
}

.contact .info-item p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.contact .php-email-form {
    background: #333333;
    padding: 35px;
    margin: 30px;
}

.contact .php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.contact-contact {
    margin: 30px;
}

.contact-contact p {
    padding: 0;
    margin: 0;
}

.contact-contact h2 {
    padding: 0;
    font-weight: 600;
    margin: 12px 0px;
}

p.text-con {
    font-size: 14px;
    padding-bottom: 10px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.followus h3 {
    font-size: 20px;
    font-weight: 600;
}

.followus h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 75px;
}

.contact .php-email-form .loading {
    display: none;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}

.foo-contact {
    border-top: 1px solid #a29d9d;
    display: flex;
    margin-top: 10px;
}

.followus a i {
    padding: 0 8px;
    font-size: 18px;
    color: #333333;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],

.contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    background-color: #212529;
    box-shadow: none!important;
    border:0;
  
}
.form-control:focus{
    color: #fff;
}
.contact .form-control{
    border-radius: 0px;
    background-color: #212529;
    border: 0;
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact select:focus,
.contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
    background: #ffffff;
    color: #151313;
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    text-transform: uppercase;
}

.contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Blog Posts Section
  --------------------------------------------------------------*/
.blog-posts {
    padding: 60px 0 20px 0;
}

.blog-posts article {
    box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
    padding: 30px;
    height: 80%;
    overflow: hidden;
    margin: auto 8px;
}

.blog-posts .post-img {
    max-height: 240px;
    margin: -30px -30px 15px -30px;
    overflow: hidden;
}

.blog-posts .post-category {
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin-bottom: 10px;
}

.blog-posts .title {
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 20px 0;
}

.blog-posts .title a {
    color: var(--heading-color);
    transition: 0.3s;
}

.blog-posts .title a:hover {
    color: var(--accent-color);
}

.blog-posts .post-author-img {
    width: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.blog-posts .post-author {
    font-weight: 600;
    margin-bottom: 5px;
}

.blog-posts .post-date {
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Pagination Section
  --------------------------------------------------------------*/
.pagination {
    padding-top: 20px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.pagination li {
    margin: 0 5px;
    transition: 0.3s;
}

.pagination li a {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination li.active,
.pagination li:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
}

.pagination li.active a,
.pagination li:hover a {
    color: var(--contrast-color);
}

/*--------------------------------------------------------------
  # Blog Details Section
  --------------------------------------------------------------*/
.blog-details {
    padding-bottom: 30px;
}

.blog-details .article {
    padding: 30px;
    box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .post-img {
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}

.blog-details .title {
    color: var(--heading-color);
    font-size: 28px;
    font-weight: 700;
    padding: 0;
    margin: 30px 0;
}

.blog-details .content {
    margin-top: 20px;
}

.blog-details .content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}

.blog-details .content blockquote {
    overflow: hidden;
    background-color: color-mix(in srgb, var(--default-color), transparent 95%);
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.blog-details .content blockquote p {
    color: var(--default-color);
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}

.blog-details .content blockquote:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(--accent-color);
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog-details .meta-top {
    margin-top: 20px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.blog-details .meta-top ul li+li {
    padding-left: 20px;
}

.blog-details .meta-top i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top a {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}

.blog-details .meta-bottom {
    padding-top: 10px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .meta-bottom i {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    display: inline;
}

.blog-details .meta-bottom a {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    transition: 0.3s;
}

.blog-details .meta-bottom a:hover {
    color: var(--accent-color);
}

.blog-details .meta-bottom .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}

.blog-details .meta-bottom .cats li {
    display: inline-block;
}

.blog-details .meta-bottom .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}

.blog-details .meta-bottom .tags li {
    display: inline-block;
}

.blog-details .meta-bottom .tags li+li::before {
    padding-right: 6px;
    color: var(--default-color);
    content: ",";
}

.blog-details .meta-bottom .share {
    font-size: 16px;
}

.blog-details .meta-bottom .share i {
    padding-left: 5px;
}

/*--------------------------------------------------------------
  # Blog Author Section
  --------------------------------------------------------------*/
.blog-author {
    padding: 10px 0 40px 0;
}

.blog-author .author-container {
    padding: 20px;
    box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-author img {
    max-width: 120px;
    margin-right: 20px;
}

.blog-author h4 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0px;
    padding: 0;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-author .social-links {
    margin: 0 10px 10px 0;
}

.blog-author .social-links a {
    color: color-mix(in srgb, var(--default-color), transparent 60%);
    margin-right: 5px;
}

.blog-author p {
    font-style: italic;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Blog Comments Section
  --------------------------------------------------------------*/
.blog-comments {
    padding-top: 10px;
}

.blog-comments .comments-count {
    font-weight: bold;
}

.blog-comments .comment {
    margin-top: 30px;
    position: relative;
}

.blog-comments .comment .comment-img {
    margin-right: 14px;
}

.blog-comments .comment .comment-img img {
    width: 60px;
}

.blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
}

.blog-comments .comment h5 a {
    font-weight: bold;
    color: var(--default-color);
    transition: 0.3s;
}

.blog-comments .comment h5 a:hover {
    color: var(--accent-color);
}

.blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-comments .comment h5 .reply i {
    font-size: 20px;
}

.blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin-bottom: 5px;
}

.blog-comments .comment.comment-reply {
    padding-left: 40px;
}

.blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
}

.blog-comments .reply-form p {
    font-size: 14px;
}

.blog-comments .reply-form input {
    background-color: var(--background-color);
    color: var(--default-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
    font-size: 14px;
    border-radius: 4px;
    padding: 10px 10px;
}

.blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: var(--accent-color);
}

.blog-comments .reply-form textarea {
    background-color: var(--background-color);
    color: var(--default-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
    height: 120px;
}

.blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: var(--accent-color);
}

.blog-comments .reply-form .form-group {
    margin-bottom: 25px;
}

.blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: var(--accent-color);
    color: var(--contrast-color);
}

.blog-comments .reply-form .btn-primary:hover {
    color: var(--contrast-color);
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
  # Portfolio Details Section
  --------------------------------------------------------------*/
.portfolio-details .portfolio-details-slider img {
    width: 100%;
}

.portfolio-details .swiper-wrapper {
    height: auto;
}

.portfolio-details .swiper-button-prev,
.portfolio-details .swiper-button-next {
    width: 48px;
    height: 48px;
}

.portfolio-details .swiper-button-prev:after,
.portfolio-details .swiper-button-next:after {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.15);
    font-size: 24px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.portfolio-details .swiper-button-prev:hover:after,
.portfolio-details .swiper-button-next:hover:after {
    background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 575px) {

    .portfolio-details .swiper-button-prev,
    .portfolio-details .swiper-button-next {
        display: none;
    }
}

.portfolio-details .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.portfolio-details .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
}

.portfolio-details .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}

.portfolio-details .portfolio-info h3:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--accent-color);
    left: 0;
    bottom: 0;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.portfolio-details .portfolio-info ul strong {
    text-transform: uppercase;
    font-weight: 400;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 14px;
}

.portfolio-details .portfolio-info .btn-visit {
    padding: 8px 40px;
    background: var(--accent-color);
    color: var(--contrast-color);
    border-radius: 50px;
    transition: 0.3s;
}

.portfolio-details .portfolio-info .btn-visit:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0;
}

.portfolio-details .portfolio-description .testimonial-item {
    padding: 30px 30px 0 30px;
    position: relative;
    background: color-mix(in srgb, var(--default-color), transparent 97%);
    margin-bottom: 50px;
}

.portfolio-details .portfolio-description .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid var(--background-color);
    float: left;
    margin: 0 10px 0 0;
}

.portfolio-details .portfolio-description .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0 5px 0;
    padding-top: 20px;
}

.portfolio-details .portfolio-description .testimonial-item h4 {
    font-size: 14px;
    color: #6c757d;
    margin: 0;
}

.portfolio-details .portfolio-description .testimonial-item .quote-icon-left,
.portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--accent-color), transparent 50%);
    font-size: 26px;
    line-height: 0;
}

.portfolio-details .portfolio-description .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
}

.portfolio-details .portfolio-description .testimonial-item p {
    font-style: italic;
    margin: 0 0 15px 0 0 0;
    padding: 0;
}

/*--------------------------------------------------------------
  # Service Details Section
  --------------------------------------------------------------*/
.service-details .service-box {
    padding: 50px 30px;
    box-shadow: 0px 2px 20px color-mix(in srgb, var(--default-color), transparent 88%);
    background: #333333;
    border-radius: 8px;
}

.service-details .service-box+.service-box {
    margin-top: 30px;
}

.service-details .service-box h4 {
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid color-mix(in srgb, var(--default-color), transparent 92%);
    padding-bottom: 15px;

}

.service-details .services-list a {
    color: #171515;
    font-size: 16px;
    font-weight: 500;
    background-image: linear-gradient(to right, white, #bab8b8, white);
    display: flex;
    align-items: center;
    padding: 12px 15px;
    border-radius: 8px;
    margin-top: 20px;
    transition: 0.3s;
}

.comman-issue img {
    padding-top: 25px;
    height: 300px;
    width: 100%;
    object-fit: cover;
}


.service-details .services-list a:first-child {
    margin-top: 0;
}

.service-details .services-list a i {
    font-size: 16px;
    margin-right: 8px;
    color: var(--accent-color);
}

.service-details .services-list a.active {
    color: var(--contrast-color);
    background-color: var(--accent-color);
}

.service-details .services-list a.active i {
    color: var(--contrast-color);
}

.service-details .services-list a:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
    color: var(--accent-color);
}

.service-details .download-catalog a {
    color: var(--default-color);
    display: flex;
    align-items: center;
    padding: 10px 0;
    transition: 0.3s;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.service-details .download-catalog a:first-child {
    border-top: 0;
    padding-top: 0;
}

.service-details .download-catalog a:last-child {
    padding-bottom: 0;
}

.service-details .download-catalog a i {
    font-size: 24px;
    margin-right: 8px;
    color: var(--accent-color);
}

.service-details .download-catalog a:hover {
    color: var(--accent-color);
}

.service-details .help-box {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    margin-top: 30px;
    padding: 30px 15px;
}

.service-details .help-box .help-icon {
    font-size: 48px;
    margin-bottom: 10px;
}

.service-details .help-box h4,
.service-details .help-box a {
    color: var(--contrast-color);
}

.service-details .services-img {
    margin-bottom: 0px;
}

.service-details .service_h2{
padding-bottom: 10px;
}
.service-details h3 ,.service-details .service_h2 {
    font-size: 26px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 62px;
}

.service-details p {
    font-size: 15px;
}

.service-details ul {

    padding: 0;
    font-size: 15px;
}

.service-details ul li {
    padding: 10px 0px;

}

.service-details ul i {
    font-size: 8px;
    margin-right: 8px;
    color: #1c1919;
}

ul.comman-is li {
    border-bottom: 1px solid #8c8c8c;

}

/*--------------------------------------------------------------
  # Starter Section Section
  --------------------------------------------------------------*/
.starter-section {
    /* Add your styles here */
}

/*--------------------------------------------------------------
  # Widgets
  --------------------------------------------------------------*/
.widgets-container {
    padding: 30px;
    margin: 60px 0 30px 0;
    box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.widget-title {
    color: var(--heading-color);
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 20px 0;
}

.widget-item {
    margin-bottom: 40px;
}

.widget-item:last-child {
    margin-bottom: 0;
}

.search-widget form {
    background: var(--background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
    padding: 3px 10px;
    position: relative;
}

.search-widget form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
    background-color: var(--background-color);
    color: var(--default-color);
}

.search-widget form input[type=text]:focus {
    outline: none;
}

.search-widget form button {
    background: var(--accent-color);
    color: var(--contrast-color);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
}

.search-widget form button i {
    line-height: 0;
}

.search-widget form button:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.categories-widget ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.categories-widget ul li {
    padding-bottom: 10px;
}

.categories-widget ul li:last-child {
    padding-bottom: 0;
}

.categories-widget ul a {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    transition: 0.3s;
}

.categories-widget ul a:hover {
    color: var(--accent-color);
}

.categories-widget ul a span {
    padding-left: 5px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 14px;
}

.recent-posts-widget .post-item {
    display: flex;
    margin-bottom: 15px;
}

.recent-posts-widget .post-item:last-child {
    margin-bottom: 0;
}

.recent-posts-widget .post-item img {
    width: 80px;
    margin-right: 15px;
}

.recent-posts-widget .post-item h4 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
}

.recent-posts-widget .post-item h4 a {
    color: var(--default-color);
    transition: 0.3s;
}

.recent-posts-widget .post-item h4 a:hover {
    color: var(--accent-color);
}

.recent-posts-widget .post-item time {
    display: block;
    font-style: italic;
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.tags-widget {
    margin-bottom: -10px;
}

.tags-widget ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tags-widget ul li {
    display: inline-block;
}

.tags-widget ul a {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 60%);
    display: inline-block;
    transition: 0.3s;
}

.tags-widget ul a:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
    border: 1px solid var(--accent-color);
}

.tags-widget ul a span {
    padding-left: 5px;
    color: color-mix(in srgb, var(--default-color), transparent 60%);
    font-size: 14px;
}

/*=======================HOME====================================== */
p.sub-heading-highlighted{
    line-height: 1.5;
    font-size: 18px;
    text-transform: uppercase;

}

.home-content h3 , .home-content h1  , .home-content h2{
    font-size: 35px;
}

.home-content h3 , .home-content h1 , .home-content h2{
    line-height: 1.4;
}

.home-content h3 , .home-content h1 ,.home-content h2 {
    line-height: 1.3;
}

.whychoose h3 {
    line-height: 1.5 !important;
}

.home-content h2.title {
    font-size: 18px;
    font-weight: 600;

}

.why-choose-title {
    font-size: 18px !important;
    font-weight: 700 !important;
    color: #333333;
    text-transform: capitalize !important;

}

.home-content h2.title a {
    color: #333333;
}

.home-content .icon {
    width: 50px;
    height: 50px;
    background: #333;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.why_choose img {
    color: #fff;
    width: 60px;
    line-height: 1.8;
    padding: 13px;
}

.essential_car_services .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.essential_car_services img {
    width: 60px;

}


.home-content .button-home {
    color: #fff;
    background-color: #323030;
    font-size: 14px;
    padding: 12px 30px;
    /* width: 35%; */
    margin: 22px 0;
    float: left;
}

.home-content .button-home a {
    color: #fff;
    text-transform: uppercase;
}

.button-sect {
    border-top: 2px solid #a6a6a6;
}

.call-us {
    /* padding: 20px; */
    /* padding-left: 43%; */
}

.call-us span {
    font-weight: 700;
    font-size: 21px;
}

.play-video {
    position: absolute;
    bottom: 40.3%;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 15px;
    width: 225px;
    color: #fff;
    font-size: 13px;
    margin-bottom: 4px;
    background: #333333;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.bi-youtube::before {
    content: "\f62b";
    background: #b3acac;
    width: 39px;
    color: white;
    height: 39px;
    padding-top: 6px;
    border-radius: 50%;
    text-align: center;
}

.video-play-sec {
    position: relative;
}

section#services-provider {
    background: #333333;
}

.four-secton-box .info-item.aos-init.aos-animate {
    text-align: center;
}

.four-secton-box h3 {
    font-size: 30px;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.home-content {
    padding-bottom: 140px;
}

.four-secton-box {
    /* margin-top: -140px; */
    padding: 30px;
    background: black;
    /* top: 50%; */
    left: 50%;
    transform: translate(-50%, -80%);
    position: absolute;
    width: 1100px;
}

.container.sec-servic-three {
    padding-top: 97px;

}

.sec-servic-three p,
.sec-servic-three h3 {
    color: #fff;
}

.sec-servic-three p {
    line-height: 1;
    text-transform: uppercase;
    font-size: 15px;
}

.services-provider h2 {
    font-size: 30px;
    line-height: 1.3;
}

p.text-portfolio {
    text-transform: capitalize;
    font-size: 14px;
    padding-top: 40px;
    line-height: 1.7;
}

p.text-bottom {
    text-transform: capitalize;
    font-size: 14px;
    padding-top: 40px;
    line-height: 1.7;
    background: linear-gradient(to right, #ffffff 0%, #ffffff 40%, #c8c8c8 50%, #ffffff 60%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.services-provider .button-home {
    text-align: center;
    color: #323030;
    background: linear-gradient(to right, #ffffff 0%, #aeacac 20%, #ffffff 100%);
    font-size: 14px;
    letter-spacing: 2px;
    padding: 15px 18px;
    width: auto;

    transition: background 0.3s ease-in;
    /* Add transition for the background */
}

.services-provider .button-home:hover {
    background: #f6f6f6;
    cursor: pointer;
    /* background: linear-gradient(to right, #ffffff 0%, #ffffff 25%, #aeacac 50%, #ffffff 80%, #ffffff 100%); */
}


.services-provider a.btn-getstarted {
    color: #252323;
    text-transform: uppercase;
    font-weight: 500;
}

.three-blog-sect .button-home {
    margin: auto;
}

.three-blog-sect p {
    text-transform: initial;
    line-height: 23px;
    padding-bottom: 24px;
    text-transform: capitalize!important;
}

.three-blog-sect h4 {
    color: #fffdfd;
    padding: 28px 0px;
}

.three-blog-sect .social {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d7d7d7;
    margin: auto;
    position: absolute;
    top: -30px;
    left: 43%;
    /* margin-top: -25px; */
}

.three-blog-sect i {
    color: #000;
    font-size: 26px;
}

.three-blog-sect .member-info.text-center {
    padding: 0 22px;
}

.sec-servic-three .member .member-info.text-center {
    background: #fff;
    position: relative;
    padding-bottom: 0px;
}

.three-blog-sect h4 {
    color: #323232;
    padding: 50px 0px;
}

.three-blog-sect p {
    color: #171616;
}

.three-blog-sect .member-img {
    padding: 0 14px;
    background: #fff;
    object-fit: contain !important;
}

.three-blog-sect img.img-fluid {
    margin-top: -15px;
}

.row.gy-5.three-blog-sect {
    margin-top: 4px;
}

.three-blog-sect p {
    color: #171616;
    /* height: 115px; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.our-services-description {
    color: #171616;
    line-height: 1.4;

}

.three-blog-sect .button-home {}

.service-details h4 , .service-details h2  {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    /* line-height: 36px; */
}
.service-details h4{
    font-weight: 400;
}
.sec-servic-three h3{
    font-size: 15px;
}
.sec-servic-three p {
    /* color: #eaeaea; */
    font-size: 15px;
    text-transform: uppercase;
    /* line-height: 36px; */
}


.service-item.d-flex.border-require {
    border-top: 1px solid #4b4444;
    border-bottom: 1px solid #4b4444;
}

.whychoose .service-item.d-flex {
    padding: 15px 0px;
    align-items: center;
}

.whychoose img.img-fluid.services-img {
    /* max-height: 112%; */
    /* margin-top: -100px; */
    width: 100%;
}

.whychoose .play-video {
    text-align: center;
    bottom: -4px;
    width: auto !important;
    gap: 12px;
}

.three-blog-sect .member-info.text-center h3 {
    color: #333;
}

.whychoose .play-video h3 {
    color: #fff;
}

.whychoose {
    padding-bottom: 0;
}

/*=====================pricing=====================*/
/* section#pricing {
    background: #333333;
    /* margin-top: -18px; */


/*=========================portfolio=======================*/
.portfolio.section h4 {
    font-size: 15px;
    text-transform: uppercase;
    line-height: 36px;
}

.portfolio.section h3 {
    font-size: 35px;
    font-weight: 700;
}

.portfolio.section p.text-bottom {
    color: #282626;
    padding-bottom: 30px;
}

.portfolio.section .button-home {
    text-align: center;
    background-color: #323030;
    font-size: 14px;
    padding: 15px 18px;
    width: 129px;
}

.portfolio.section .button-home a.btn-getstarted {
    color: #d8d1d1;
    text-transform: uppercase;
}

.isotope-layout img.img-fluid {
    width: 100%;
}

.isotope-layout .portfolio-item.item3 img.img-fluid {
    min-height: 253px;
    /* padding-bottom: 25px; */
}

.testimonials .container.section-title.text-left.aos-init.aos-animate {
    text-align: left;
}

.testimonials h3,
.testimonials h4 {
    text-align: left;
    margin: 18px 0;
    color: #e5e0e0;
}
.testimonials .para_content{
    font-weight: 400;
    text-align: left;
    margin: 18px 0;
    font-family: var(--heading-font);
    font-size: 15px;
}

.testimonials .price-head {
    background: transparent;
    text-align: center;
}

.testimonials h3 {
    text-align: center;
}

.testimonials .price-head h3 {
    text-align: center;
    margin: 0;
}

.testimonials .price-amount h4 {
    margin: 0;
    text-transform: initial;
    padding-top: 0;
    padding-bottom: 20px;
}

.testimonials.pricing .pricing-item {
    padding: 30px 40px;
}

.testimonials.pricing .pricing-item p {
    text-align: left;
}

.stars i.bi.bi-star-fill {
    color: #ffc412;
    padding: 0 4px;
}

.blog-content h4.title {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 19px;
    line-height: 25px;
}

.blog-content .service-item {
    padding-bottom: 30px;
}

.blog-content .service-item .date {
    padding-bottom: 10px;
    font-size: 15px;
}

/* .services-banner img {
    max-height: 485px;
  } */
.col-lg-8.main-banner.aboutus {
    position: absolute;
    top: 10%;
}

.services-banner {
    position: relative;
}

.aboutus h1 {
    font-size: 50px;
    font-weight: 800;
}

.about-sus a.btn-getstarted.f-right {
    background: #323030;
    color: #fff;
}

.play-video.about {
    position: absolute;
    bottom: 4.1%;
    padding-left: 26px;
    padding-top: 15px;
    width: 225px;
    color: #fff;
    font-size: 13px;
    margin-bottom: 4px;
    background: #f32e09;
    transform: skew(356deg);
    height: 95px;
}

.aboutimg img {
    width: 100%;
    max-height: 300px;
}

.aboutimg {
    padding-top: 35px;
}

.brand-about {
    padding: 50px 0px;
}

.brand-about img {
    /* width: 60%; */
    margin: auto 20%;
    object-fit: contain;
}

.brand-about h5 {
    background: #f3f3f7;
    font-weight: 700;
    padding: 13px 0;
    text-align: center;
}

.brand-about h3 {
    text-align: center;
    padding-bottom: 25px;
    font-size: 22px;
}

.container.ourvalues {
    text-align: center;
}

.ourvalues h4 {
    text-align: center;
    padding-bottom: 25px;
    margin-top: 60px;
    font-size: 22px;
    text-transform: uppercase;
}

.ourvalues h3 {
    font-weight: 800;
    font-size: 33px;
}

.ourvalues .icon-box {
    background: #f3f3f7;
    padding: 35px;
    height: 80%;
    margin-bottom: 100px;
}

.ourvalues .icon-box h3 {
    font-weight: 700;
    padding: 15px;
    font-size: 27px;
}

.ourvalues .icon-box i {
    background: #000;
    color: #fff;
    position: absolute;
    line-height: 53px;
    font-size: 28px;
    top: -5px;
    width: 50px;
    margin-left: -30px;
    text-align: center;
    height: 53px;
}

.ourvalues .icon-boxes {
    position: relative;
}

.discount-offers h5 {
    text-align: center;
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
}

.discount-offers .stats-item p {
    font-size: 14px;
    line-height: 23px;
    padding: 15px 0;
}

section.stats.section.discount-offers {
    padding: 71px 0px;
}

.teamsec p {
    padding-bottom: 30px;
}

.teamsec .col-lg-3 img {
    width: 100%;
}

.teamsec .col-lg-3 {
    padding: 4px;
}

.CallToActionBarWraper {
    position: relative;
    /* Ensure that the div is positioned relative for the overlay */
    /* width: 100dvw; */
    /* height: 200px; */
    padding: 40px 0px;
    /* Adjust the height as needed */
    background: linear-gradient(rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)),
        url('../images/services-first.png') center/cover no-repeat;
    /* display: flex;
    align-items: center;
    justify-content: center;
    color: white; */
    /* Text color */
    /* text-align: center;
    padding: 20px; */
}

.iconCustomerService {
    position: relative;
}

.iconCustomerService {
    background: white;
    width: 60px;
    height: 60px;
    color: #212121;
    font-size: 28px;
    /* line-height: 0; */
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
}

.footerIcons {
    background: #212121;
    width: 48px;
    height: 48px;
    color: white;
    font-size: 24px;
    /* line-height: 0; */
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
}

.item3 {
    display: flex !important;
    flex-direction: column !important;
}

.item3 img {
    flex: 1 !important;
    padding-bottom: 0px;
    object-fit: cover;

}

.portfolio-image-wraper {
    padding: 0px 20px 0px 0px !important
}

.portfolio-image-wraper2 {
    padding: 0px 0px 0px 20px !important
}

.blog-post-img {
    height: 260px !important;
    width: 100% !important;
    object-fit: cover !important;
}

.info-cards {
    /* background-image: linear-gradient(to right, #ffffff, #aeacac, #ffffff,)!important; */
    background-image: linear-gradient(to right, #ffffff 0%, #ffffff 25%, #aeacac 50%, #ffffff 80%, #ffffff 100%) !important;
    height: 100%;
}

.primary-icon {
    background: linear-gradient(to right, #ffffff 0%, #ffffff 25%, #aeacac 50%, #ffffff 80%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.our-services-card {
    background: linear-gradient(to right, #ffffff 0%, #ffffff 25%, #aeacac 50%, #ffffff 80%, #ffffff 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.our-services-card p {
    margin-bottom: 0px;
    margin-top: 8px;
}

.sub-heading-highlighted {
    letter-spacing: 2px !important;
    font-weight: 500;
}

.primary-gradient-text-small {
    font-weight: 400;
    background: linear-gradient(to right, #ffffff 0%, #b5b5b5 20%, #ffffff 40%, #ffffff 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.primary-gradient-text {
    font-weight: 700;
    background: linear-gradient(to right, #ffffff 0%, #ffffff 15%, #c9c9c9 30%, #ffffff 60%, #aeacac 80%, #ffffff 100%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.services-card-title {
    font-weight: 700 !important;
    color: #32353a !important;
    text-transform: capitalize !important;
}

.whychoose-description {
    line-height: 1.6 !important;
}

@media (max-width: 600px) {


    .Right-div img {
        display: none;
    }

    .four-secton-box {
        transform: translate(0px);
        position: unset;
    }

    .about {
        padding-bottom: 0px !important;
    }

    .whychoose img.img-fluid.services-img {
        margin-top: 30px;
    }

    /* In your CSS file, e.g., styles.css */
    .truncated-text {
        display: -webkit-box !important;
        -webkit-box-orient: vertical !important;
        -webkit-line-clamp: 4 !important;
        /* Number of lines to show */
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: normal !important;
    }

    .appointment .service-box.contact-form {
        position: unset;
        width: auto;
        margin-bottom: 50px;
    }

    .our-services-card,
    .info-cards {
        margin-bottom: 10px;
        height: auto;

    }

    header .logo img {

        padding-left: 0px !important;
        margin-left: -20px;
    }

    nav.secound-banner-sec {
        height: auto;
    }

    .left-div {
        width: 100%;
    }

    .left-div h3 {
        margin-bottom: 10px;
    }

    .Right-div {
        width: 100%;
        position: unset;
        float: none;
        text-align: left;
        margin-top: 16px;
    }

    .left-div p {
        line-height: 1.2;

    }

}

/* src/Sidebar.css */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #fff;
    padding-top: 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 55555555;
}

.sidebar.active {
    transform: translateX(0);


}

.sidebar .dropdown button {
    background-color: transparent !important;
    border: none;
    color: var(--nav-color);
}

.sidebar .nav-link {
    color: var(--nav-color);

    font-family: var(--nav-font);
    font-size: 16px;
}

.sidebar .nav-link:hover {
    color: var(--accent-color);
}

.sidebar .dropdown .dropdown-item a {
    color: var(--nav-color);

}

.sidebar .dropdown .dropdown-item {
    margin-bottom: 5px;
}

.service-item {
    margin-bottom: 10px;
}

.top-foot p a {
    color: #212529;
}

.why_wrenchit {
    margin-top: 30px;
}


/* responsive for services pages */
/* Laptop View */
@media screen and (max-width: 1200px) and (min-width: 900px) {
    /* .benefits-container {
        padding: 60px 40px;
        flex-direction: row;
        gap: 40px;
    } */
}

@media screen and (max-width: 992px) {
    /* .comman-issue img {
        margin-bottom: 20px;
    } */

    .comman-is {
        padding-left: 16px !important;
    }

    /* custom padding top bottom */
    .py-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .px-12{
        padding-left: 12px;
        padding-right: 12px;
    }

    /* sections padding */
    .Benefits {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .services-des {
        padding-top: 60px;
        margin-top: 0;
    }

    /* button */
    .pricing .buy-btn {
        width: 100%;
        position: static;
        transform: none;


    }
}

/* Tablet View */
@media screen and (max-width: 900px) {

    /* .benefits-container {
        padding: 40px 50px;
        flex-direction: column;
        gap: 40px;
    } */
    .Right-div img {
        width: 220px;
        /* position: absolute; */
        right: 20%;
        /* top: -80px; */
    }


    .service-details ul {

        padding-left: 2rem;
    }

    /* .comman-issue img {
        margin-bottom: 20px;
    }

    .comman-is {
        padding-left: 16px !important;
    }

    .Benefits {
        padding: 60px 10px;
    } */
}

/* Mobile View */
@media screen and (max-width: 763px) {
    .secound-banner-sec .left-div {
        width: auto;
    }

    .secound-banner-sec .left-div p {
        margin-bottom: 0;
    }

    .secound-banner-sec .container {
        display: flex;
    }

    .Right-div img {
        width: 100px !important;
        position: static;
        /* Change to 'relative' if necessary */
        right: auto;
        top: auto;
        order: 1;
    }

    .secound-banner-sec .container .Right-div {
        width: auto;
        float: none;
        position: static;
        text-align: left;
        /* Default text alignment */
        margin-top: 0;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .Right-div a.btn-getstarted.f-right {
        width: max-content;
        height: fit-content;
        order: 2;
    }

    .secound-banner-sec {
        height: auto !important;
    }



}

/* Mobile View */
@media screen and (max-width: 600px) {
    /* .benefits-container {
        padding: 30px 20px;
        flex-direction: column;
    } */
    /* .secound-banner-sec .container{
        display: flex;
        gap: 16px;
    } */
}

/* Small Mobile View */
@media screen and (max-width: 480px) {

    /* .benefits-container {
        padding: 20px;
        flex-direction: column;
        gap: 30px;
      } */
    .secound-banner-sec {
        padding: 20px !important;
    }

    .secound-banner-sec .container {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .Right-div {
        margin: 0px;
        height: 43px;
    }

    /* #service-details .container .row .col-lg-4{
        order: 2;
    } */
    .services-des h3 {
        line-height: 1.3;
        margin-bottom: 16px;
    }

    .services-des ul {
        padding-left: 16px !important;
    }

    .service-details .service-box {
        padding: 40px 20px;
    }

    /* .BenefitsSection div:nth-child(2) {
        padding-left: 0px;
    } */
    .service-item div:nth-child(1) {
        margin-right: 16px !important;
    }

    .pricing .buy-btn {
        width: 100%;
        position: static;

    }

    .pricing h3 {
        font-size: 20px;
        line-height: 1.2;
    }

    .services .service-item .icon {
        width: 48px;
        height: 48px;
    }

    .essential_car_services img {
        width: 36px;
    }
}

/* GlamourPricing.css */
.form-control.date-input,
.form-control.time-input {
  color:#b3acac!important ;
}

.form-control.date-input.selected,
.form-control.time-input.selected {
  color: #2d2a2a!important;
}

.php-email-form .form-control.date-input.selected,
.php-email-form .form-control.time-input.selected {
  color: #fff!important;
}
.contact-form .form-control.date-input.selected,
.contact-form .form-control.time-input.selected {
  color: #fff!important;
}


.flatpickr-wrapper , .flatpickr-input{
    width: 100%;
    color: #fff!important;
}
.flatpickr-wrapper .time-bg , .flatpickr-input{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


/* time and date */
.react-datepicker-wrapper{
    width: 100%;
}
.date-picker-appoitment {
    color: #fff;
    padding: .375rem 2.25rem .375rem .75rem;
    border:none!important;
    width: 100%;
    background-color: #0f0f0f;
    cursor: pointer;
    height: 44px;
  }

  .date-picker-appoitment:focus-visible{
    border: none!important;
    outline: none;
  }
  .date-picker {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-shadow: none!important; 
}
  .date-picker:focus-visible{
    outline: var(--bs-border-width) solid var(--bs-border-color);
    box-shadow: none!important;
  
}

.date-picker:focus{
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
}
  .date-picker.selected {
    color: #2d2a2a;
  }

/*  */

.wrapper-1{
    width:100%;
    height:100vh;
    display: flex;
  flex-direction: column;
  }
  .wrapper-2{
    padding :30px;
    text-align:center;
  }

  .wrapper-2 p{
    margin:0;
    font-size:16px;

    letter-spacing:1px;
  }

.content h1{
    font-size: 42px;
}
  @media (min-width:600px){
    .content{
    margin:0 auto;
  }
    .wrapper-1{
    height: initial;
max-width: 800px;
    margin:0 auto;
    margin-top:50px;
  }
    
  }
 
  .blog_page {
    background: #333333;
    padding: 80px;
    text-align: center;
  }
  .blog_page_detail
  {
    background: #333333;
    padding: 80px;
    text-align: center;
    height: 400px;
  }
  .blog_page h1{
    color: #fff;
  }
  .latest_blog a
  {
    font-size: 14px!important;
  }
  .latest_blog h3
  {
    font-size: 18px!important ;
  }
  h2{
    margin: 20px 0px;
    font-weight: 600;
    font-size: 22px;
  }
  .latest_blog p{
    font-size: 12px!important;
  }
  .blog_grid p{
    font-size: 14px;
  }
  .blog_grid {
    padding: 50px 0px;
  }
  .blog_grid button{
    background: #323030;
    color: #fff;
    border-radius: 0px;
    padding: 15px 30px;
    margin-top: 10px;
  }
  .blog_grid button:hover{
    border: 1px black solid !important;
  }
  .latest_blog h3{
    font-size: 20px;

  }
  .img_blog_detail 
  {
    /* height: 600px!important;
    width: 600px!important; */
    object-fit: cover;
    border-radius: 30px!important;
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: -150px;
  }


  .latest_blog p{
    font-size: 14px;
    margin: 0px;
    padding: 0px;
  }
  .latest_blog a{
    font-size:16px;
  }
.recent_post .row{
    margin-bottom: 20px;
}

#shadow-host-companion{
    display: none;
}

.blog_details h3{
    font-size: 20px;
}