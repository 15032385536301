.modal-dialog{
    max-width: 700px!important;
}
.modal-form .form-control{
box-shadow: none!important;
appearance: auto!important;
color-scheme: dark;
color: #2d2a2a!important;
}
.form-control:focus{
    color: #2d2a2a;
}
